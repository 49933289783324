import React, { useState } from 'react';
import { useIntersection } from '../hooks/useIntersection';
import mapmark from '../Assets/mapmark.png';

function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    mobile: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [id]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await fetch('http://www.chakragroup.in/contact.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams(formData).toString(),
    });
    const result = await response.json();
    alert(result.message); // Show a message based on the response
  };

  const [ref, isVisible] = useIntersection({ threshold: 0.1 });

  return (
    <div
      ref={ref}
      className={`bg-blue-300 p-4 transform transition-all duration-1000 ease-in-out ${
        isVisible ? 'opacity-100' : 'opacity-50'
      }`}
    >
      <div id="contact" className="md:p-32">
        <div className="md:grid grid-cols-3 md:gap-20 text-black">
          <div className="col-span-1 text-xl">
            <h1 className="font-bold">Write Us</h1>
            <form className="mt-4" onSubmit={handleSubmit}>
              <div className="grid grid-cols-1 gap-4">
                <div>
                  <input
                    type="text"
                    id="name"
                    placeholder="Name"
                    value={formData.name}
                    onChange={handleChange}
                    className="w-full p-2 border-b border-gray-300 bg-transparent text-black focus:outline-none focus:ring-0"
                  />
                </div>
                <div>
                  <input
                    type="text"
                    id="mobile"
                    placeholder="Mobile"
                    value={formData.mobile}
                    onChange={handleChange}
                    className="w-full p-2 border-b border-gray-300 bg-transparent text-black focus:outline-none focus:ring-0"
                  />
                </div>
                <div>
                  <input
                    type="email"
                    id="email"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleChange}
                    className="w-full p-2 border-b border-gray-300 bg-transparent text-black focus:outline-none focus:ring-0"
                  />
                </div>
                <div className="mb-4">
                  <textarea
                    id="message"
                    placeholder="Message"
                    value={formData.message}
                    onChange={handleChange}
                    className="w-full p-1 border-b border-gray-300 bg-transparent text-black focus:outline-none focus:ring-0"
                    rows="3"
                  ></textarea>
                </div>
                <div className="flex justify-end">
                  <button
                    type="submit"
                    className="bg-transparent text-black font-bold py-1 px-4 border border-gray-300 rounded hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="col-span-1 text-xl">
            <h1 className="font-bold">Meet Us</h1>
            <p className="mt-4 text-black">
              403, Alcazar Plaza, Rd Number 1, Dwarakapuri, Banjara Hills, Hyderabad, Telangana 500034
            </p>
            <div className="mt-10 flex items-center">
              <img src={mapmark} className="h-20 w-20" alt="Map Marker" />
              <a
                className="hover:text-black"
                href="https://maps.google.com?q=403,+Alcazar+Plaza,+Rd+Number+1,+Dwarakapuri,+Banjara+Hills,+Hyderabad,+Telangana+500034"
                target="_blank"
                rel="noopener noreferrer"
              >
                Link to view in the map
              </a>
            </div>
          </div>
          <div className="col-span-1 text-xl">
            <h1 className="font-bold">Visit Us</h1>
            <p className="mt-4 text-black">
              <a
                href="https://www.chakragroup.in/"
                target="_blank"
                rel="noopener noreferrer"
                className="cursor-pointer hover:underline"
              >
                ChakraGroup
              </a>
              <br />
              <a
                href="https://www.joybharath.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="cursor-pointer hover:underline"
              >
                Joy Bharath
              </a>
              <br />
              <a
                href="https://www.algoworks.co.in/"
                target="_blank"
                rel="noopener noreferrer"
                className="cursor-pointer hover:underline"
              >
                Algo Works
              </a>
              <br />
              <a
                href="https://www.amigosdolls.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="cursor-pointer hover:underline"
              >
                Amigo Dolls
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
