import React, { useEffect } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Gallery.css';
import { useIntersection } from '../hooks/useIntersection';
import AOS from 'aos';
import 'aos/dist/aos.css';
import img1 from '../Assets/image-1.jpg';
import img2 from '../Assets/image-2.jpg';
import img3 from '../Assets/image-3.jpg';

function Gallery() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',
    });
  }, []);

  const [ref, isVisible] = useIntersection({ threshold: 0.1 });

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      }
    ]
  };

  return (
    <div id='gallery' className='text-center'>
      <h1 className='text-3xl font-bold text-blue-900 mt-10' data-aos="fade-down">
        PHOTO GALLERY
      </h1>
      <div className='mx-6 mt-10'>
        <div 
          ref={ref} 
          className={`transform transition-all duration-1000 ease-in-out ${isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}
        >
          <Slider {...settings} className='space-x-8'>
            <div className='slide-container'>
              <img src={img1} alt='Gallery Item 1' className='gallery-image' />
            </div>
            <div className='slide-container'>
              <img src={img2} alt='Gallery Item 2' className='gallery-image' />
            </div>
            <div className='slide-container'>
              <img src={img3} alt='Gallery Item 3' className='gallery-image' />
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
}

export default Gallery;
