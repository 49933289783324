import React, { useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp, faLinkedin, faFacebook, faYoutube, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import AOS from 'aos';
import 'aos/dist/aos.css';

const FloatingButtons = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',
    });
  }, []);

  return (
    <div className="z-50 fixed bottom-4 right-4 flex flex-col space-y-3 md:space-y-4 lg:space-y-5">
      <a
        href="https://wa.me/9390227940"
        target="_blank"
        rel="noopener noreferrer"
        className="bg-green-500 p-3 md:p-4 rounded-full shadow-lg hover:bg-green-600 transition transform hover:scale-110"
      >
        <FontAwesomeIcon icon={faWhatsapp} className="text-white w-5 h-5 md:w-6 md:h-6 lg:w-7 lg:h-7" />
      </a>
      <a
        href="https://linkedin.com/in/chakra-group-46351931a"
        target="_blank"
        rel="noopener noreferrer"
        className="bg-blue-700 p-3 md:p-4 rounded-full shadow-lg hover:bg-blue-800 transition transform hover:scale-110"
      >
        <FontAwesomeIcon icon={faLinkedin} className="text-white w-5 h-5 md:w-6 md:h-6 lg:w-7 lg:h-7" />
      </a>
      <a
        href="https://x.com/Chakrainfotain?t=8nLRAxitqc9zTURMh17EKA&s=09"
        target="_blank"
        rel="noopener noreferrer"
        className="bg-black p-3 md:p-4 rounded-full shadow-lg hover:bg-black transition transform hover:scale-110"
      >
        <FontAwesomeIcon icon={faXTwitter} className="text-white w-5 h-5 md:w-6 md:h-6 lg:w-7 lg:h-7" />
      </a>
      <a
        href="https://www.facebook.com/profile.php?id=100077853575857&mibextid=ZbWKwL"
        target="_blank"
        rel="noopener noreferrer"
        className="bg-blue-800 p-3 md:p-4 rounded-full shadow-lg hover:bg-blue-900 transition transform hover:scale-110"
      >
        <FontAwesomeIcon icon={faFacebook} className="text-white w-5 h-5 md:w-6 md:h-6 lg:w-7 lg:h-7" />
      </a>
      <a
        href="https://www.youtube.com/@chakrainfotainment6507"
        target="_blank"
        rel="noopener noreferrer"
        className="bg-red-600 p-3 md:p-4 rounded-full shadow-lg hover:bg-red-700 transition transform hover:scale-110"
      >
        <FontAwesomeIcon icon={faYoutube} className="text-white w-5 h-5 md:w-6 md:h-6 lg:w-7 lg:h-7" />
      </a>
    </div>
  );
};

export default FloatingButtons;
